import TrafficQuality from '@/services/classes/TrafficQuality.js'
import TrafficPlatform from '@/services/classes/TrafficPlatform.js'

export default class AdFormat {
  id = null
  name = ''
  description = ''
  pricingModels = []
  size = ''
  type = ''
  sourceType = ''
  icon = ''
  targetTypes = []
  trafficQualities = []
  trafficPlatforms = []

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.name = data.name ?? ''
    this.description = data.description ?? ''
    this.pricingModels = data.pricing_models ?? []
    this.size = data.size ?? ''
    this.sourceType = data.source_type ?? ''
    this.type = data.type ?? ''
    this.icon = data.icon ?? ''
    this.targetTypes = data.target_types ?? []
    this.trafficQualities = data.traffic_qualities?.map((trafficQuality) => new TrafficQuality(trafficQuality)) ?? []
    this.trafficPlatforms = data.traffic_platforms?.map((trafficPlatform) => new TrafficPlatform(trafficPlatform)) ?? []
  }
}
