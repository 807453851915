import moment from 'moment'
import Ads from '@/services/classes/Ads.js'
import createCalendarLimits from '@/services/utils/create-calendar-limits.js'
import Approval from '@/services/classes/Approval.js'
import CampaignTargets from '@/services/classes/CampaignTargets.js'
import limitTypes from '@/types/limit-types.js'
import CampaignGroup from '@/services/classes/CampaignGroup.js'
import SiteNew from '@/services/classes/SiteNew.js'
import Rule from '@/services/classes/Rule.js'

export default class Campaign {
  actions = {}
  activateOnApprove = true
  activeFrom = null
  activeTo = null
  ad = new Ads()
  adFormat = {}
  calendarLimits = createCalendarLimits()
  campaignGroup = new CampaignGroup()
  cpaGoals = []
  cpaStatus = 'disabled'
  dailyClickLimit = null
  conversionType = {}
  dailyImpressionLimit = null
  dailyMoneyLimit = null
  createdAt = ''
  hourlyClickLimit = null
  hourlyImpressionLimit = null
  hourlyMoneyLimit = null
  id = null
  latestApproval = new Approval()
  limitExceededName = null
  limitExceededType = null
  limitType = limitTypes.MONEY
  name = ''
  nextStatus = null
  price = 0
  pricingModel = null
  reservedBalance = null
  smartBidding = false
  statisticGatheringDone = true
  status = ''
  targets = new CampaignTargets()
  totalClickLimit = null
  totalImpressionLimit = null
  totalMoneyLimit = null
  enablePriceAutoAdjust = false
  recommendedPrice = 0
  priceAdjustmentRatio = 0
  maxAdjustedPrice = null
  flatDealApplication = this.#createFlatDealApplication()
  siteBiddings = []
  updatingRules = []

  constructor(data) {
    if (!data) return

    this.actions = data.actions
    this.activateOnApprove = data.activate_on_approve
    this.activeFrom = data.active_from ? moment(data.active_from).format('YYYY-MM-DD') : null
    this.activeTo = data.active_to ? moment(data.active_to).format('YYYY-MM-DD') : null
    this.ad = new Ads(data.ad)
    this.adFormat = this.createAdFormat(data.ad_format || {})
    this.calendarLimits = createCalendarLimits(data.calendar_limits)
    this.campaignGroup = new CampaignGroup(data.campaign_group)
    this.cpaGoals = this.createCpaGoals(data.cpa_goals)
    this.cpaStatus = data.cpa_status ?? 'disabled'
    this.dailyClickLimit = data.daily_click_limit
    this.dailyImpressionLimit = data.daily_impression_limit
    this.dailyMoneyLimit = data.daily_money_limit
    this.conversionType = data.conversion_type
    this.createdAt = moment(data.created_at).utc().format('YYYY-MM-DD HH:mm:ss')
    this.hourlyClickLimit = data.hourly_click_limit
    this.hourlyImpressionLimit = data.hourly_impression_limit
    this.hourlyMoneyLimit = data.hourly_money_limit
    this.id = data.id
    this.latestApproval = new Approval(data.latest_approval)
    this.limitExceededName = data.limit_exceeded_name
    this.limitExceededType = data.limit_exceeded_type
    this.limitType = data.limit_type
    this.name = data.name
    this.nextStatus = data.next_status
    this.price = data.price
    this.pricingModel = data.pricing_model
    this.reservedBalance = data.reserved_balance
    this.smartBidding = data.smart_bidding
    this.statisticGatheringDone = data.statistic_gathering_done
    this.status = data.status
    this.targets = new CampaignTargets(data.targets)
    this.totalClickLimit = data.total_click_limit
    this.totalImpressionLimit = data.total_impression_limit
    this.totalMoneyLimit = data.total_money_limit
    this.enablePriceAutoAdjust = data.enable_price_auto_adjust
    this.recommendedPrice = data.recommended_price
    this.priceAdjustmentRatio = +data.price_adjustment_ratio
    this.maxAdjustedPrice = data.max_adjusted_price
    this.flatDealApplication = this.#createFlatDealApplication(data.flat_deal_application)
    this.siteBiddings = this.createSiteBiddings(data.site_biddings)
    this.updatingRules = data.updating_rules?.map((item) => new Rule(item)) ?? []
  }

  static transformForUpdatePriceRequest(data) {
    return {
      price: data.price,
      enable_price_auto_adjust: data.enablePriceAutoAdjust,
      max_adjusted_price: data.maxAdjustedPrice,
      price_adjustment_ratio: data.priceAdjustmentRatio
    }
  }

  createSiteBiddings(data) {
    return (
      data?.map((bidding) => ({
        sites: bidding.sites.map((site) => new SiteNew(site)),
        price: bidding.price
      })) ?? []
    )
  }

  get dailyLimit() {
    return {
      [limitTypes.MONEY]: this.dailyMoneyLimit,
      [limitTypes.IMPRESSIONS]: this.dailyImpressionLimit,
      [limitTypes.CLICKS]: this.dailyClickLimit
    }[this.limitType]
  }

  get hourlyLimit() {
    return {
      [limitTypes.MONEY]: this.hourlyMoneyLimit,
      [limitTypes.IMPRESSIONS]: this.hourlyImpressionLimit,
      [limitTypes.CLICKS]: this.hourlyClickLimit
    }[this.limitType]
  }
  get totalLimit() {
    return {
      [limitTypes.MONEY]: this.totalMoneyLimit,
      [limitTypes.IMPRESSIONS]: this.totalImpressionLimit,
      [limitTypes.CLICKS]: this.totalClickLimit
    }[this.limitType]
  }

  get isFlatDeal() {
    return !!this.flatDealApplication.id
  }

  createAdFormat(data) {
    return {
      description: data.description,
      icon: data.icon,
      id: data.id,
      name: data.name,
      size: data.size,
      type: data.type
    }
  }
  createCpaGoals(data) {
    return (
      data?.map((cpaGoal) => ({
        price: cpaGoal.price,
        countries: cpaGoal.countries.map((country) => ({ id: country.id, name: country.name }))
      })) ?? []
    )
  }

  #createFlatDealApplication(data) {
    return {
      id: data?.id ?? null
    }
  }

  static getOfferCampaign(
    data,
    {
      countries,
      languages,
      trafficTypes,
      interests,
      ip2LocationTypes,
      operatingSystems,
      deviceVendors,
      deviceModels,
      browsers,
      carriers,
      sites,
      categories,
      categoryGroups,
      regions,
      cities,
      campaignGroups
    }
  ) {
    if (!data.targets) return []

    const offerTargets = {
      ...countries && data.targets.country_target && {
        country_target: {
          countries: data.targets.country_target?.country_ids.map((countryId) => {
            const currentCountry = countries.find((collectionCountry) => collectionCountry.id === countryId) ?? {}
            return {
              id: countryId,
              iso_code_2: currentCountry.iso_code_2,
              iso_code_3: currentCountry.iso_code_3,
              name: currentCountry.name
            }
          })
        }
      },
      ...regions && data.targets.region_target && {
        region_target: {
          accept: !!data.targets.region_target.accept,
          regions: data.targets.region_target?.region_ids.map((regionId) => {
            const currentRegion = regions.find((collectionRegion) => collectionRegion.id === regionId) ?? {}
            return {
              id: regionId,
              country_id: currentRegion.country_id,
              name: currentRegion.name
            }
          })
        }
      },
      ...cities && data.targets.city_target && {
        city_target: {
          accept: !!data.targets.city_target.accept,
          cities: data.targets.city_target?.city_ids.map((cityId) => {
            const currentCity = cities.find((collectionCity) => collectionCity.id === cityId) ?? {}
            return {
              id: cityId,
              region_id: currentCity.region_id,
              name: currentCity.name
            }
          })
        }
      },
      ...languages && data.targets.language_target && {
        language_target: {
          accept: true,
          languages: data.targets.language_target?.language_ids.map((languageId) => {
            const currentLanguage = languages.find((collectionLanguage) => collectionLanguage.id === languageId) ?? {}
            return {
              id: languageId,
              name: currentLanguage.name,
              iso_code_2: currentLanguage.iso_code2
            }
          })
        }
      },
      ...trafficTypes && data.targets.traffic_type_target && {
        traffic_type_target: {
          traffic_type: {
            id: data.targets.traffic_type_target?.traffic_type_id,
            boss_tds_os_types: trafficTypes.find((type) => type.id === data.targets.traffic_type_target?.traffic_type_id).boss_tds_os_types,
            name: trafficTypes.find((type) => type.id === data.targets.traffic_type_target?.traffic_type_id).name
          }
        }
      },
      ...interests && data.targets.interest_target && {
        interest_target: {
          accept: true,
          interests: data.targets.interest_target.interest_ids.map((interestId) => {
            const currentInterest = interests.find((interest) => interest.id === interestId)
            return {
              id: interestId,
              name: currentInterest.name
            }
          })
        }
      },
      ...ip2LocationTypes && data.targets.ip2_location_type_target && {
        ip2_location_type_target: {
          ip2_location_types: data.targets.ip2_location_type_target.ip2_location_type_ids.map((ip2id) => {
            const currentIp2 = ip2LocationTypes.find((type) => type.id === ip2id) ?? {}
            return {
              id: ip2id,
              name: currentIp2.name
            }
          })
        }
      },
      ...operatingSystems && data.targets.operating_system_target && {
        operating_system_target: {
          operating_systems: data.targets.operating_system_target.operating_system_ids.map((systemId) => {
            const currentSystem = operatingSystems.find((system) => system.id === systemId) ?? {}
            return {
              id: systemId,
              name: currentSystem.name,
              boss_tds_useragent_name: currentSystem.boss_tds_useragent_name
            }
          })
        }
      },
      ...deviceVendors && data.targets.device_vendor_target && {
        device_vendor_target: {
          reject: !!data.targets.device_vendor_target.accept || null, // в оффере accept,  в таргетах  reject
          device_vendors: data.targets.device_vendor_target.device_vendor_ids.map((deviceId) => {
            const currentDevice = deviceVendors.find((device) => device.id === deviceId) ?? {}
            return {
              id: deviceId,
              name: currentDevice.name
            }
          })
        }
      },
      ...deviceModels && data.targets.device_model_target && {
        device_model_target: {
          reject: !!data.targets.device_model_target.accept || null,
          device_models: data.targets.device_model_target.device_model_ids.map((deviceId) => {
            const currentDevice = deviceModels.find((device) => device.id === deviceId) ?? {}
            return {
              id: deviceId,
              name: currentDevice.name,
              device_vendor_id: currentDevice.device_vendor_id
            }
          })
        }
      },
      ...browsers && data.targets.browser_target && {
        browser_target: {
          accept: !!data.targets.browser_target.accept,
          browsers: data.targets.browser_target.browser_ids.map((browserId) => {
            const currentBrowser = browsers.find((browser) => browser.id === browserId) ?? {}
            return {
              id: browserId,
              name: currentBrowser.name,
              boss_tds_useragent_name: currentBrowser.boss_tds_useragent_name,
              version: data.targets.browser_target.version
            }
          })
        }
      },
      ...carriers && data.targets.carrier_target && {
        carrier_target: {
          reject: !!data.targets.carrier_target.accept,
          type: data.targets.carrier_target.type,
          carriers: data.targets.carrier_target.carrier_ids.map((carrierId) => {
            const currentCarrier = carriers.find((carrier) => carrier.id === carrierId) ?? {}
            return {
              id: carrierId,
              name: currentCarrier.name
            }
          })
        }
      },
      ...categories && data.targets.category_target && {
        category_target: {
          categories: data.targets.category_target.category_ids.map((categoryId) => {
            const currentCategory = categories.find((category) => category.id === categoryId) ?? {}
            return {
              id: categoryId,
              name: currentCategory.name
            }
          })
        }
      },
      ...categoryGroups && data.targets.category_group_target && {
        category_group_target: {
          accept: data.targets.category_group_target.accept,
          category_groups: data.targets.category_group_target.category_group_ids.map((categoryId) => {
            const currentCategoryGroup = categoryGroups.find((category) => category.id === categoryId) ?? {}
            return {
              id: categoryId,
              name: currentCategoryGroup.name
            }
          })
        }
      },
      ...sites && data.targets.site_target && {
        site_target: {
          accept: !!data.targets.site_target.accept,
          premium: !!data.targets.site_target.premium,
          sites: data.targets.site_target?.site_ids.map((siteId) => {
            const currentSite = sites.find((site) => site.id === siteId) ?? {}
            return {
              id: siteId,
              premium: currentSite.premium,
              is_marketplace: currentSite.is_marketplace,
              tubecorp: currentSite.tubecorp,
              is_new: currentSite.isNew,
              domain: currentSite.domain,
              billing_domain: currentSite.billing_domain
            }
          }) || []
        }
      },
      traffic_quality_target: {
        quality: data.targets.traffic_quality_target?.quality
      },
      ...data.targets.uniques_target && {
        uniques_target: {
          accept: !!data.targets.uniques_target,
          cap: data.targets.uniques_target.cap,
          ttl: data.targets.uniques_target.ttl
        }
      },
      ...data.targets.in_page_traffic_target && {
        in_page_traffic_target: {
          accept: data.targets.traffic_quality_target
        }
      },
      ...data.targets.ip_ranges_target && {
        ip_ranges_target: data.targets.ip_ranges_target
      },
      ...data.targets.web_push_subscription_time_target && {
        web_push_subscription_time_target: {
          min_days: data.targets.min_days,
          max_days: data.targets.max_days
        }
      },
      ...data.targets.ipv6_target && {
        ipv6_target: {
          accept: !!data.targets.ipv6_target.accept
        }
      },
      ...data.targets.throttle_target && {
        throttle_target: {
          smooth_spend_rate: data.targets.throttle_target.smooth_spend_rate
        }
      }
    }
    return {
      ...campaignGroups && data.campaign_group_id && {
        campaign_group: {
          id: data.campaign_group_id,
          name: campaignGroups.find(({ id }) => id === data.campaign_group_id)?.name
        }
      },
      activate_on_approve: data.activate_on_approve,
      active_from: data.active_from,
      active_to: data.active_to,
      calendar_limits: data.calendar_limits,
      daily_click_limit: data.daily_click_limit,
      daily_impression_limit: data.daily_impression_limit,
      daily_money_limit: data.daily_money_limit,
      enable_price_auto_adjust: data.enable_price_auto_adjust,
      hourly_click_limit: data.hourly_click_limit,
      hourly_impression_limit: data.hourly_impression_limit,
      hourly_money_limit: data.hourly_money_limit,
      limit_type: data.limit_type,
      max_adjusted_price: data.max_adjusted_price,
      name: data.name,
      price: data.price,
      price_adjustment_ratio: data.price_adjustment_ratio,
      pricing_model: data.pricing_model,
      smart_bidding: data.smart_bidding,
      total_click_limit: data.total_click_limit,
      total_impression_limit: data.total_impression_limit,
      total_money_limit: data.total_money_limit,
      targets: offerTargets
    }
  }

  get getCampaignFormParams() {
    return {
      name: this.name,
      enablePriceAutoAdjust: this.enablePriceAutoAdjust,
      activateOnApprove: this.activateOnApprove,
      selectedCampaignGroup: this.campaignGroup,
      selectedAdFormat: this.adFormat,
      webpushSubscriptionTime: this.targets.webPushSubscriptionTimeTarget,
      selectedPlacementTypes: this.targets.placementTypeTarget,
      cappingAccept: this.targets.uniquesTarget.accept || false,
      cappingTtl: this.targets.uniquesTarget.ttl,
      countOfCreativesForCappingTtl: this.targets.uniquesTarget.cap,
      conversionType: this.conversionType,
      pricingModel: this.pricingModel,
      cpaGoals: this.cpaGoals,
      price: this.price,
      priceAdjustmentRatio: this.priceAdjustmentRatio,
      maxAdjustedPrice: this.maxAdjustedPrice,
      selectedAd: this.ad,
      activeFrom: this.activeFrom,
      activeTo: this.activeTo,
      categoryIds: this.targets.categoryTarget.map(({ id }) => id),
      categoryGroupIds: this.targets.categoryGroupTarget.map(({ id }) => id),
      inPageTrafficTarget: this.targets.inPageTrafficTarget,
      trafficQualityType: this.targets.trafficQualityType,
      interestTarget: this.targets.interestTarget.map(({ id }) => id),
      siteBiddings: this.siteBiddings,
      campaignTrafficPlatformTargetId: this.targets.campaignTrafficPlatformTarget.campaignTrafficPlatforms,
      campaignTrafficPlatformTargetIsLocked: this.targets.campaignTrafficPlatformTarget.isLocked,

      selectedCountries: this.targets.countryTarget,
      providersTarget: this.targets.providersTarget,
      selectedRegions: this.targets.regionTarget,
      selectedCities: this.targets.cityTarget,
      connectionType: this.targets.carrierTarget.type || null,
      selectedCarriers: this.targets.carrierTarget.carriers,
      selectedTrafficType:
        (this.targets.trafficTypeTarget.id && this.targets.trafficTypeTarget) || null,
      selectedDeviceVendors: this.targets.deviceVendorTarget,
      selectedDeviceModels: this.targets.deviceModelTarget,
      selectedBrowsers: this.targets.browserTarget,
      selectedLanguages: this.targets.languageTarget,
      selectedOperatingSystems: this.targets.operatingSystemTarget,
      selectedIp2LocationTypes: this.targets.ip2LocationTypeTarget,
      blacklistIpRanges:
        (!this.targets.ipRangesTarget.accept && this.targets.ipRangesTarget.ranges) || '',
      ipv6Target: this.targets.ipv6Target.accept,
      isIpRangesMode: !!this.targets.ipRangesTarget.accept,
      ipRanges: (this.targets.ipRangesTarget.accept && this.targets.ipRangesTarget.ranges) || '',

      blackWhiteSitesType: this.targets.siteTarget.accept === false ? 'blacklist' : 'whitelist',
      selectedSites: this.targets.siteTarget.sites,
      isPremiumSites: this.targets.siteTarget.premium || false,
      isAllPremiumSites: !(this.targets.siteTarget.premium && this.targets.siteTarget.accept),
      premiumSitesIds: this.targets.siteTarget.sites.map(({ id }) => id),
      isManualApps: false,
      blackWhiteAppsType: this.targets.mobileAppTarget.accept === false ? 'blacklist' : 'whitelist',
      selectedApps: this.targets.mobileAppTarget.mobileApps,
      selectedSpots: this.targets.spotTarget.spots,
      blackWhiteSpotsType: this.targets.spotTarget.accept === false ? 'blacklist' : 'whitelist',

      limitType: this.limitType,
      hourlyLimit: this.hourlyLimit,
      dailyLimit: this.dailyLimit,
      totalLimit: this.totalLimit,
      smoothSpendRate: this.targets.throttleTarget,
      calendarLimits: this.calendarLimits,
      updatingRules: this.updatingRules
    }
  }
}
