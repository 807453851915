import { adFormats } from '@clickadilla/components/constants/ad-formats.js'

export default function getCampaignStatisticsGroupByOptions() {
  return {
    date: {
      label: 'dates',
      startGroup: true,
      filters: ['cost', 'impressions', 'clicks', 'video_views', 'ctr', 'conversions', 'payouts'],
      headers: [
        'date',
        'cost',
        'impressions',
        'clicks',
        'video_views',
        'ctr',
        'conversions',
        'payouts'
      ]
    },
    hour: {
      label: 'hours',
      filters: ['cost', 'impressions', 'clicks', 'video_views', 'ctr', 'conversions', 'payouts'],
      headers: [
        'hour',
        'cost',
        'impressions',
        'clicks',
        'video_views',
        'ctr',
        'conversions',
        'payouts'
      ]
    },
    campaign: {
      label: 'campaigns',
      startGroup: true,
      filters: ['cost', 'impressions', 'clicks', 'video_views', 'ctr', 'conversions', 'payouts'],
      headers: [
        'campaign_id',
        'campaign',
        'cost',
        'reserved_balance',
        'impressions',
        'clicks',
        'video_views',
        'ctr',
        'conversions',
        'payouts'
      ]
    },
    creative: {
      label: 'creatives',
      filters: ['impressions', 'clicks', 'ctr', 'cost', 'conversions'],
      headers: ['src', 'creative_id', 'cost', 'conversions', 'ad_type_name', 'impressions', 'clicks', 'ctr']
    },
    browser: {
      label: 'browsers',
      startGroup: true,
      filters: ['cost', 'impressions', 'clicks', 'ctr', 'conversions'],
      headers: ['browser', 'cost', 'conversions', 'impressions', 'clicks', 'ctr']
    },
    carrier: {
      label: 'carriers',
      filters: ['cost', 'impressions', 'clicks', 'ctr', 'conversions'],
      headers: ['carrier', 'cost', 'conversions', 'impressions', 'clicks', 'ctr']
    },
    country: {
      label: 'countries',
      filters: ['cost', 'impressions', 'clicks', 'ctr', 'conversions'],
      headers: ['country', 'cost', 'conversions', 'impressions', 'clicks', 'ctr']
    },
    operatingSystem: {
      label: 'operating_systems',
      filters: ['cost', 'impressions', 'clicks', 'ctr', 'conversions'],
      headers: ['operatingSystem', 'cost', 'conversions', 'impressions', 'clicks', 'ctr']
    },
    spot: {
      label: 'spots',
      icon: 'label-new',
      filters: ['conversions', 'cost', 'ctr', 'impressions', 'clicks'],
      headers: [
        'tds_id',
        'clicks',
        'conversions',
        'cost',
        'ctr',
        'impressions'
      ]
    },
    site: {
      label: 'sites',
      filters: ['cost', 'impressions', 'clicks', 'ctr', 'conversions'],
      headers: ['site', 'cost', 'conversions', 'impressions', 'clicks', 'ctr']
    },
    trafficPlatform: {
      label: 'traffic_platform',
      filters: ['cost', 'impressions', 'clicks', 'ctr', 'conversions'],
      headers: [
        'traffic_platform',
        'cost', 'conversions', 'impressions', 'clicks', 'ctr'
      ]
    },
    trafficProvider: {
      label: 'providers',
      filters: ['requests', 'bids', 'wins', 'spend'],
      headers: ['provider', 'requests', 'bids', 'wins', 'spend']
    },
    banner: {
      label: 'banners',
      startGroup: true,
      filters: ['impressions', 'clicks', 'ctr'],
      headers: ['id', 'banner', 'type', 'impressions', 'clicks', 'ctr'],
      type: adFormats.BANNER
    },
    webPush: {
      label: 'web_pushes',
      filters: ['impressions', 'clicks', 'ctr'],
      headers: ['id', 'webPush', 'impressions', 'clicks', 'ctr'],
      type: adFormats.WEB_PUSH
    },
    inPageAd: {
      label: 'in_page_ads',
      filters: ['impressions', 'clicks', 'ctr'],
      headers: ['id', 'inPageAd', 'impressions', 'clicks', 'ctr'],
      type: adFormats.IN_PAGE_AD
    },
    inStreamAd: {
      label: 'instream',
      filters: ['impressions', 'clicks', 'video_views', 'ctr'],
      headers: ['id', 'inStreamAd', 'impressions', 'clicks', 'video_views', 'ctr'],
      type: adFormats.IN_STREAM_AD
    },
    nativeAd: {
      label: 'native',
      filters: ['impressions', 'clicks', 'ctr'],
      headers: ['id', 'nativeAd', 'impressions', 'clicks', 'ctr'],
      type: adFormats.NATIVE_AD
    },
    directLink: {
      label: 'direct_link',
      filters: ['impressions', 'clicks', 'ctr'],
      headers: ['id', 'direct_link', 'impressions', 'clicks', 'ctr'],
      type: adFormats.DIRECT_LINK
    },
    iosCalendar: {
      label: 'ios_calendar',
      filters: ['clicks'],
      headers: ['id', 'iosCalendar', 'clicks'],
      type: adFormats.IOS_CALENDAR
    },
    gallery: {
      label: 'gallery_ad',
      filters: ['clicks'],
      headers: ['id', 'gallery', 'clicks'],
      type: adFormats.GALLERY
    }
  }
}
