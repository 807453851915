const state = {
  browsers: [],
  carriers: [],
  categories: [],
  categoryGroups: [],
  countries: [],
  deviceModels: [],
  ip2LocationTypes: [],
  languages: [],
  operatingSystems: [],
  trafficTypes: [],
  deviceVendors: [],
  interests: [],
  messengers: [],
  conversionTypes: [],
  inPageSkinGroups: [],
  spots: [],
  trafficPlatforms: [],

  trafficPlatformsAreLoading: false,
  browsersAreLoading: false,
  carriersAreLoading: false,
  categoriesAreLoading: false,
  categoryGroupsAreLoading: false,
  countriesAreLoading: false,
  deviceModelsAreLoading: false,
  ip2LocationTypesAreLoading: false,
  languagesAreLoading: false,
  operatingSystemsAreLoading: false,
  trafficTypesAreLoading: false,
  deviceVendorsAreLoading: false,
  interestsAreLoading: false,
  messengersAreLoading: false,
  conversionTypesAreLoading: false,
  inPageSkinGroupsAreLoading: false,
  spotsAreLoading: false,

  connectionTypes: [
    { type: 'all', name: 'All types' },
    { type: 'wifi', name: 'LAN & Wi-Fi' },
    { type: 'accept', name: 'Mobile carriers' }
  ]
}

export default state
