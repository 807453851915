import Cookies from 'js-cookie'
import getQueryParams from '@/services/utils/get-query-params.js'
import getDomainName from '@/services/utils/get-domain-name.js'

const cookieTokens = ['affiliate_code', 'registration_params']

export function saveQueryParamsToCookie() {
  const allQueryParams = getQueryParams(window.location.href)
  Object.keys(allQueryParams).forEach((param) => {
    const cookieValue = param === 'ref' ? 'affiliate_code' : param
    if (cookieTokens.includes(cookieValue)) {
      Cookies.set(cookieValue, allQueryParams[param], {
        expires: 7,
        domain:
          process.env.NODE_ENV === 'production' ? `.${getDomainName(window.location.hostname)}` : ''
      })
    }
  })
}

export function getSavedCookieParams() {
  return cookieTokens.reduce((acc, param) => {
    acc = {
      [param]: Cookies.get(param) ?? '',
      ...acc
    }
    return acc
  }, {})
}

export function removeCookie(cookieName) {
  Cookies.remove(cookieName, {
    path: '/',
    domain: `.${getDomainName(window.location.hostname)}`
  })
}
