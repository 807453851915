export default class TrafficPlatform {
  categoryGroupId = null
  trafficPlatformId = null

  constructor(data) {
    if (!data) return

    this.trafficPlatformId = data?.campaign_traffic_platform_id ?? null
    this.categoryGroupId = data?.category_group_id ?? null
  }
}
